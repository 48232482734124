import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallax, mobileAbout, mobileAboutParallax } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          {/* <CFImage
            src={mobileAboutParallax}
            w="100%"
            pv="5px"
            alt="Tokyo John Sushi Restaurant About"
          /> */}
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="Tokyo John Sushi Restaurant About"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView>
          {/* <CFView
            column
            justifyEnd
            alignCenter
            h="500px"
            w="100%"
            maxWidth="1400px"
            image={`url(${aboutParallax}) bottom / cover no-repeat`}
            style={{ backgroundAttachment: 'fixed' }}
          ></CFView> */}
          <CFImage src={about} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
